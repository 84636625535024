/*项目相关*/
import request from '@/utils/request'

//发布3分钟访谈——找人/找钱（融资）/找智慧
export function publishInterviewForPerson(data) {
    return request({
        url: '/interview/publishInterviewForPerson',
        method: 'post',
        data
    })
}
